.mainContainer {
  h1 {
    font-size: 16px;
  }

  align-items: center;
  gap: 3rem;

  margin: 2rem;

  .paragraphContainer {
    margin: 0 5rem;
  }

  img {
    border-radius: 50%;
    width: max(10vw, 5rem);
    height: auto;

    margin-bottom: 2rem;
  }

  .splitQualifications {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 3rem 1rem;

    width: 100%;

    > * {
      flex: 1 0 0;
    }
  }

  section {
    width: 100%;
    backdrop-filter: blur(5px);

    header {
      display: flex;
      flex-flow: column;
      align-items: center;

      margin-bottom: 1rem;
    }
  }
}
