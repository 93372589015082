.mainRouterContainer {
  width: 100%;
  height: 100%;
}

.bannerWrapper {
  width: 100%;
  //justify-content: flex-end;

  margin-bottom: 3rem;

  img {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    justify-self: flex-start;
    filter: contrast(1.25);  // To sharpen the image a bit post down-sampling

  }
}
