.outerProjectContainer {
  flex-wrap: wrap;
  gap: 3rem 1rem;
}

.outerProjectContainer h2 {
  font-size: 130%;
  margin-left: 0.5rem;
}

.projectRows :global(.card),
.contribRows :global(.card) {
  background-color: rgba(255, 255, 255, 0.90);
}

.projectRow {
  .projectImage {
    flex: 1 0 0;

    min-width: 0;

    object-fit: scale-down;

    margin-right: 2em;

    filter: contrast(1.125);
  }

  .languageImage {
    height: 2rem;
  }

  > .detailSection {
    flex: 10 0 0;
  }
}

h3 {
  font-size: 120%;

  > a {
    color: #8c3f22;
  }
}

.projectRows,
.contribRows {
  flex: 1 0 0;
  width: 0;

  min-width: 15rem;
}
