body {
  /* Stolen from SO */
  font-family: BlinkMacSystemFont, "Segoe UI","Liberation Sans", sans-serif;

  background-image: url("assets/mandelBackground.png");
  background-color: #d8bdb4;
  background-repeat: no-repeat;  // Figure out how to flip on Y-axis
  background-size: cover;
  background-attachment: fixed;

  margin: 1rem;

  font-weight: 500;
}

@mixin mandelAccentShadowBox($alpha) {
  box-shadow: 0 10px 10px 3px rgba(70, 35, 10, $alpha);
}

@mixin cardRaised {
  margin: 0.5rem;
  padding: 1rem;

  border-radius: 5px;
}

.card {
  @include cardRaised;
  @include mandelAccentShadowBox(0.3);
}

.cardLight {
  @include cardRaised;
  @include mandelAccentShadowBox(0.05);
}

.containerRow {
  display: flex;
  flex-direction: row;
}

.containerCol {
  display: flex;
  flex-direction: column;
}

main {
  a {
    color: rgb(200, 58, 51);
  }

  a:hover {
    color: rgb(200, 58, 51);
  }

  a:visited {
    color: rgb(139, 40, 35);
  }
}


/* Because flex displays will still show otherwise */
[hidden] {
  display: none !important;
}


