nav {
  height: 10vmin;
  width: 100%;

  //border: dotted blue;

  align-items:flex-end;

  ul {
    justify-content: center;
    align-content: center;

    list-style: none;

    padding: 0;
    margin: 0;

    a {
      color: var(--standard-fg-color);
      text-decoration: none;
      padding: 0 6px;
    }
  }
}

.navbarItem {
  display: inline-block;

  font-weight: 700;
  background-color: #0000;
  border: none;

  transition: transform 750ms, color 500ms;

  &:hover {
    color: var(--standard-fg-color);
    transform: translateY(-0.15rem);
  }
}

.navbarItem.activeLink {
  color: rgb(129, 63, 34);
}

